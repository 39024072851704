.row-text-form {
  position: relative;
  .u-grid--text {
    @include grid-container();
    align-items: flex-start;
    @media screen and (min-width: $container-max-width + 1px) {
      @include grid-container($text-form-gutter);
    }
  }
  &__col{
    @include grid-col(12);
    @media screen and (min-width: 771px){
      @include grid-col($text-form-text-grid);
    }
    @media screen and (min-width: $container-max-width + 1px) {
      @include grid-col($text-form-text-grid, $text-form-gutter);
    }
  }
  &__form{
    @include grid-col(12);
    @media screen and (min-width: 771px){
      @include grid-col($text-form-form-grid);
    }
    @media screen and (min-width: $container-max-width + 1px) {
      @include grid-col($text-form-form-grid, $text-form-gutter);
    }
  }
  &.left {
    .row-text-form__col {
      order: 2;
      padding-top: 1.25em;
      @media screen and (min-width: 771px){
        padding-top: 0;
      }
    }
    .row-text-form__form {
      order: 1;
    }
  }
  &.right {
    .row-text-form__col {
      order: 2;
      padding-top: 1.25em;
      @media screen and (min-width: 771px){
        order: 1;
        padding-top: 0;
      }
    }
    .row-text-form__form {
      order: 1;
      @media screen and (min-width: 771px){
        order: 2;
      }
    }
  }
}