$grid-columns: 12 !default;
$grid-gutter: 20px !default;
// grid-brake-points
$breakpoints: (xs, 350px),
              (sm, 550px),
              (md, 770px),
              (xl, 1180px)!default;

//make grid breakpoints
@mixin media-max-width() {
  @each $breakpoint, $width in $breakpoints {
    @media screen and (max-width: $width) {
    }
  }
}

//make grid container
@mixin grid-container($gutter: $grid-gutter, $jc: flex-start) {
  position: relative;

  display: flex;

  width: calc(100% + #{$gutter});
  margin: 0 -#{$gutter / 2};

  flex-wrap: wrap;
  justify-content: $jc;
}

// make widths for grid items or if needed custom
@mixin grid-width($parts:1, $gutter: $grid-gutter) {
  width: calc(100% / #{$grid-columns} * #{$parts} - #{$gutter});
}

// make margins for grid items or if needed custom
@mixin grid-margin($gutter: $grid-gutter) {
  margin-left: #{$gutter / 2};
  margin-right: #{$gutter / 2};
}

//make grid columns
@mixin grid-col($parts:1, $gutter: $grid-gutter) {
  @include grid-width($parts, $gutter);
  @include grid-margin($gutter)
}

.u-grid {
  @include grid-container;
}

@for $i from 1 through $grid-columns {
  .u-grid-#{$i} {
    @include grid-col($i);
  }
}

@each $breakpoint, $width in $breakpoints {
  @media screen and (min-width: $width + 1px) {
    @for $i from 1 through $grid-columns {
      .u-grid-#{$breakpoint}-#{$i} {
        @include grid-col($i);
      }
    }
  }
}