html {
  font-family: $font-primary;
  color: $color-text;
  font-size: $font-size;
  *{
    line-height: 1.25;
    @media screen and (min-width: 771px) {
      line-height: 1.375;
    }
  }
}

body {
  overflow-x: hidden;

  min-width: 320px;
}

a {
  transition: all .25s ease;
  text-decoration: none;

  color: inherit;
}

