.hero {
  position: relative;
  z-index: 25;

  background: {
    size: cover;
    repeat: no-repeat;
  }

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba($color-primary,0.5) 0%, rgba($color-secondary,0.5) 100%);
  }

  .u-content-block {
    min-height: 100vh;
    align-content: center;
    align-items: center;
    padding-top: 20vh;
    padding-bottom: 6vh;

    @media screen and (max-width: 550px) {
      padding-top: 5vh;
    }
  }

  &__main {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    &__top-title {
      font-size: clamp(20px, 1.05vw, 26px);
      color: $color-white;
      width: 100%;
      position: relative;
      line-height: 1.5;
      padding-bottom: 15px;
    }

    &__text {
      font-size: clamp(18px, 1.05vw, 24px);
      color: $color-white;
      width: 100%;
      position: relative;
      line-height: 1.5;
      padding-bottom: 15px;
    }
  }
}