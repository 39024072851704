.u-clearfix:after {
  display: table;
  clear: both;

  content: ' ';
}

.u-table {
  display: table;
}

.u-tr {
  display: table-row;
}

.u-td {
  display: table-cell;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-content-row {
  position: relative;

  width: 100%;
  padding: $row-side-padding 0;
}

.u-content-block {
  position: relative;

  display: flex;

  width: $container-width;
  max-width: $container-max-width;
  margin: auto;
  padding: 0 $container-side-padding;

  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 5;
}
.u-content-title{
  width: $container-width;
  max-width: $container-max-width;
  margin: 0 auto $row-side-padding;
  padding: 0 $container-side-padding;
  text-align: center;
  position: relative;
  z-index: 5;
}

.u-img-container {
  position: relative;
  img[src=''] {
    display: none;
  }
  &--cover{
    @extend .u-img-container;
    background: url('../img/no-image.svg') no-repeat center center / cover;
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;
      margin: auto;

      pointer-events: none;

      object-fit: cover;
    }
  }
  &--contain{
    display: flex;
    justify-content: center;
    align-items: center;

    @extend .u-img-container;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

  }
}

.u-bg{
  background: no-repeat center center / cover;
  margin: $row-side-padding 0;
  + [class*="u-bg"]{
    margin-top: -$row-side-padding;
  }
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
